import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { SectionContainer, shouldForwardProp } from "@italymondo/core-ui";
import { Stack } from "@mui/material";

export const Heading = styled(Stack)`
  ${({ theme }): SerializedStyles => css`
    margin: 0 auto ${theme.spacing(3)};
    ${theme.cssMixins.sectionHeading};
  `}
`;

export const MobileContainer = styled("div")`
  ${({ theme }): SerializedStyles => css`
    padding-top: ${theme.spacing(8)};
    padding-bottom: ${theme.spacing(8)};
    ${theme.breakpoints.up("md")} {
      display: none;
    }
  `}
`;

export const DesktopContainer = styled(SectionContainer)`
  ${({ theme }): SerializedStyles => css`
    @media (max-width: 1250px) {
      width: 100vw !important;
      overflow: hidden;
    }
    ${theme.breakpoints.down("md")} {
      display: none;
    }
  `}
`;

type PpImagesContainer = {
  $isBigger: boolean;
  $isInverted: boolean;
};

export const PhotoTileContainer = styled("div", {
  shouldForwardProp,
})<PpImagesContainer>`
  ${({ $isBigger, $isInverted }): SerializedStyles => css`
    @media (max-width: 1250px) {
      width: 1276px;
      margin: 0 auto;
    }
    ${$isBigger
      ? css`
          display: grid;
          grid-template-columns: ${$isInverted
            ? "30% 25% 15% auto"
            : "25% 10% 25% auto"};
          grid-template-rows: 1fr 1fr;
          gap: 30px;
        `
      : css`
          display: grid;
          grid-auto-flow: column;
          grid-template-rows: minmax(1fr, 500px);
          gap: 30px;
        `};
  `}
`;

export const ImageWrapperMobile = styled("div")`
  ${({ theme }): SerializedStyles => css`
    &:focus,
    &:active {
      outline: none;
    }
    padding: ${theme.spacing(1.5)};
    img {
      height: 260px;
      width: 100%;
      object-fit: cover;
    }
    ${theme.breakpoints.up("sm")} {
      padding: ${theme.spacing(3)};
      img {
        height: 360px;
        width: 100%;
        object-fit: cover;
      }
    }
  `}
`;

export const ImageWrapperDesktop = styled("div", {
  shouldForwardProp,
})<PpImagesContainer>`
  ${({ $isBigger, $isInverted, theme }): SerializedStyles => css`
    overflow: hidden;
    border-radius: ${theme.shape.siteBorderRadius.lg};
    box-shadow: ${theme.shadows[2]};
    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    ${$isBigger &&
    $isInverted &&
    css`
      &:nth-of-type(1) {
        height: 720px;
        grid-row: span 2;
      }
      &:nth-of-type(2) {
        height: 300px;
        grid-column: span 2;
        align-self: flex-end;
      }
      &:nth-of-type(3) {
        height: 360px;
        align-self: flex-end;
      }
      &:nth-of-type(4) {
        height: 300px;
      }
      &:nth-of-type(5) {
        height: 460px;
        grid-column: span 2;
      }
    `}
    ${$isBigger &&
    !$isInverted &&
    css`
      &:nth-of-type(1) {
        height: 450px;
        grid-column: span 2;
      }
      &:nth-of-type(2) {
        height: 300px;
        align-self: flex-end;
      }
      &:nth-of-type(3) {
        position: relative;
        top: 100px;
        height: 800px;
        grid-row: span 2;
      }
      &:nth-of-type(4) {
        height: 340px;
      }
      &:nth-of-type(5) {
        height: 300px;
        grid-column: span 2;
      }
    `}
  `}
`;
