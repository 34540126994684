import React from "react";
import { Button, Founder, getPathname, Link } from "@italymondo/core-ui";
import { useStaticAboutQuery } from "./query";

export function About(): React.ReactElement | null {
  const data = useStaticAboutQuery();

  if (!data) {
    return null;
  }

  return (
    <Founder
      actions={
        <Link to={getPathname(data?.serviceIndexPage[0]?.aboutCta?.page?.url)}>
          <Button color="primary" variant="contained">
            {data?.serviceIndexPage[0]?.aboutCta?.title}
          </Button>
        </Link>
      }
      alignCenter={false}
      description={data?.serviceIndexPage[0].aboutDescription || ""}
      media={data?.serviceIndexPage[0].aboutMedia[0]}
      mediaPlacement={data?.serviceIndexPage[0].aboutMediaPlacement}
      sectionName={data?.serviceIndexPage[0].aboutSectionName || ""}
      title={data?.serviceIndexPage[0].aboutTitle || ""}
    />
  );
}
