import React from "react";
import { getImage, ImageDataLike } from "gatsby-plugin-image";
import { StyledImg } from "./styles";

export type PpImage = {
  alt: string;
  classNames?: string;
  fullWidth?: boolean;
  src: ImageDataLike;
};

export function Image({
  alt,
  classNames = "",
  fullWidth = false,
  src,
  ...rest
}: PpImage): React.ReactElement {
  return (
    <StyledImg
      alt={alt}
      image={getImage(src)}
      {...rest}
      $fullWidth={fullWidth}
      imgClassName={`styled-image ${classNames}`}
    />
  );
}
