import { graphql, useStaticQuery } from "gatsby";
import { ServiceIndexDifferencesQuery } from "./query.generated";

export type CMSDifferencesData = ServiceIndexDifferencesQuery["cms"]["site"];

export function useStaticDifferencesQuery(): CMSDifferencesData {
  const data = useStaticQuery<ServiceIndexDifferencesQuery>(
    graphql`
      query ServiceIndexDifferences {
        cms {
          site(hostname: "www.italymondo.com") {
            serviceIndexPage: pages(
              contentType: "italymondo_services.IMServiceIndexPage"
            ) {
              ... on CMS_IMServiceIndexPage {
                differences {
                  difference {
                    description
                    icon {
                      url
                    }
                    title
                  }
                }
                differenceDescription
                differenceSectionName
                differenceTitle
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
