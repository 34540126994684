import React from "react";
import { PhotoTile as PhotoTileComponent } from "../../../components";
import { useStaticPhotoTileQuery } from "./query";

export function PhotoTile(): React.ReactElement | null {
  const data = useStaticPhotoTileQuery();

  if (!data?.serviceIndexPage[0]) {
    return null;
  }

  return (
    <PhotoTileComponent
      description={data?.serviceIndexPage[0]?.galleryDescription || ""}
      images={data?.serviceIndexPage[0].images.map((i) => ({
        alt: i.image.alt,
        src: i.image.urlSharp,
      }))}
      title={data?.serviceIndexPage[0]?.galleryTitle || ""}
    />
  );
}
