import { graphql, useStaticQuery } from "gatsby";
import { HeroServiceIndexQuery } from "./query.generated";

export type CMSHeroData = HeroServiceIndexQuery["cms"]["site"];

export function useStaticHeroQuery(): CMSHeroData {
  const data = useStaticQuery<HeroServiceIndexQuery>(
    graphql`
      query HeroServiceIndex {
        cms {
          site(hostname: "www.italymondo.com") {
            serviceIndexPage: pages(
              contentType: "italymondo_services.IMServiceIndexPage"
            ) {
              ... on CMS_IMServiceIndexPage {
                __typename
                alertBar {
                  anchorText
                }
                heroDescription
                heroHeadline
                heroImage {
                  alt
                  url
                  urlSharp {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
                heroPrimaryButton {
                  page {
                    url
                  }
                  title
                }
                heroSecondaryButton {
                  page {
                    url
                  }
                  title
                }
                heroType
                heroVerticalText {
                  name
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
