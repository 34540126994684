import { graphql, useStaticQuery } from "gatsby";
import { ServiceIndexMetricsQuery } from "./query.generated";

export type CMSMetricsData = ServiceIndexMetricsQuery["cms"]["site"];

export function useStaticMetricsQuery(): CMSMetricsData {
  const data = useStaticQuery<ServiceIndexMetricsQuery>(
    graphql`
      query ServiceIndexMetrics {
        cms {
          site(hostname: "www.italymondo.com") {
            serviceIndexPage: pages(
              contentType: "italymondo_services.IMServiceIndexPage"
            ) {
              ... on CMS_IMServiceIndexPage {
                metrics {
                  metric {
                    name
                    value
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
