import { graphql, useStaticQuery } from "gatsby";
import { ServiceIndexAboutQuery } from "./query.generated";

export type CMSAboutData = ServiceIndexAboutQuery["cms"]["site"];

export function useStaticAboutQuery(): CMSAboutData {
  const data = useStaticQuery<ServiceIndexAboutQuery>(
    graphql`
      query ServiceIndexAbout {
        cms {
          site(hostname: "www.italymondo.com") {
            serviceIndexPage: pages(
              contentType: "italymondo_services.IMServiceIndexPage"
            ) {
              ... on CMS_IMServiceIndexPage {
                aboutDescription
                aboutCta {
                  page {
                    url
                  }
                  title
                }
                aboutMedia {
                  ... on CMS_MediaImagesBlock {
                    __typename
                    images {
                      image {
                        alt
                        url
                        urlSharp {
                          childImageSharp {
                            gatsbyImageData(placeholder: BLURRED, width: 900)
                          }
                        }
                      }
                    }
                  }
                  ... on CMS_TestimonialsBlock {
                    __typename
                    testimonials {
                      testimonial {
                        date
                        longMessage
                        name
                        photo {
                          alt
                          url
                          urlSharp {
                            childImageSharp {
                              gatsbyImageData(
                                height: 60
                                width: 60
                                placeholder: BLURRED
                              )
                            }
                          }
                        }
                        sourceDetail {
                          logo {
                            title
                            url
                          }
                          url
                        }
                      }
                    }
                  }
                }
                aboutMediaPlacement
                aboutSectionName
                aboutTitle
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
