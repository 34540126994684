import React from "react";
import { Metrics as MetricsComponent } from "@italymondo/core-ui";
import { useStaticMetricsQuery } from "./query";

export function Metrics(): React.ReactElement | null {
  const data = useStaticMetricsQuery();

  if (!data?.serviceIndexPage[0]) {
    return null;
  }

  return (
    <MetricsComponent
      withoutPaddingTop
      metrics={data?.serviceIndexPage[0].metrics.map((m) => m.metric) || []}
    />
  );
}
