import { graphql, useStaticQuery } from "gatsby";
import { ServiceIndexGalleryQuery } from "./query.generated";

export type CMSGalleryData = ServiceIndexGalleryQuery["cms"]["site"];

export function useStaticPhotoTileQuery(): CMSGalleryData {
  const data = useStaticQuery<ServiceIndexGalleryQuery>(
    graphql`
      query ServiceIndexGallery {
        cms {
          site(hostname: "www.italymondo.com") {
            serviceIndexPage: pages(
              contentType: "italymondo_services.IMServiceIndexPage"
            ) {
              ... on CMS_IMServiceIndexPage {
                galleryDescription
                galleryTitle
                images {
                  image {
                    alt
                    url
                    urlSharp {
                      childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
