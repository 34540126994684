import { graphql, useStaticQuery } from "gatsby";
import { ServiceIndexServicesQuery } from "./query.generated";

export type CMSServicesData = ServiceIndexServicesQuery["cms"];

export function useStaticServicesQuery(): CMSServicesData {
  const data = useStaticQuery<ServiceIndexServicesQuery>(
    graphql`
      query ServiceIndexServices {
        cms {
          site(hostname: "www.italymondo.com") {
            serviceIndexPage: pages(
              contentType: "italymondo_services.IMServiceIndexPage"
            ) {
              ... on CMS_IMServiceIndexPage {
                children {
                  ... on CMS_IMServicePage {
                    listingImage {
                      alt
                      url
                      urlSharp {
                        childImageSharp {
                          gatsbyImageData(
                            width: 550
                            layout: CONSTRAINED
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                    listingLinkText
                    listingSummary
                    listingTitle
                    slug
                    url
                  }
                }
                servicesDescription
                servicesSectionName
                servicesTitle
              }
            }
          }
        }
      }
    `
  );
  return data.cms;
}
