import React from "react";
import { getPathname } from "@italymondo/core-ui";
import { Services as ServicesComponent } from "../../../components";
import { useStaticServicesQuery } from "./query";

export function Services(): React.ReactElement | null {
  const data = useStaticServicesQuery();

  if (!data) {
    return null;
  }

  const services = data.site?.serviceIndexPage[0]?.children
    .filter((s) => Object.keys(s).length !== 0)
    .map((s) => ({
      image: {
        alt: s.listingImage?.alt || "",
        src: s.listingImage?.urlSharp || "",
      },
      label: s?.slug || "",
      link: {
        label: s.listingLinkText,
        url: getPathname(s.url),
      },
      summary: s?.listingSummary,
      title: s?.listingTitle,
    }));

  return (
    <ServicesComponent
      description={data.site?.serviceIndexPage[0]?.servicesDescription || ""}
      sectionName={data.site?.serviceIndexPage[0]?.servicesSectionName || ""}
      services={services}
      title={data.site?.serviceIndexPage[0]?.servicesTitle || ""}
    />
  );
}
