import { getPathname, PpHero } from "@italymondo/core-ui";
import { CMSHeroData } from "./query";

export function formatData(data: CMSHeroData, showAlertBar: boolean): PpHero {
  if (!data) {
    throw new Error("Expected a site object");
  }

  return {
    description: data?.serviceIndexPage[0].heroDescription || "",
    hasAlertBar: showAlertBar && !!data?.serviceIndexPage[0].alertBar,
    headline: data?.serviceIndexPage[0].heroHeadline || "",
    heroType: data?.serviceIndexPage[0].heroType,
    imageSrc: data?.serviceIndexPage[0].heroImage?.urlSharp || "",
    primaryButton: data?.serviceIndexPage[0]?.heroPrimaryButton
      ? {
          title: data?.serviceIndexPage[0].heroPrimaryButton?.title,
          url: getPathname(
            data?.serviceIndexPage[0]?.heroPrimaryButton?.page?.url
          ),
        }
      : null,
    secondaryButton: data?.serviceIndexPage[0]?.heroSecondaryButton
      ? {
          title: data?.serviceIndexPage[0]?.heroSecondaryButton?.title,
          url: getPathname(
            data?.serviceIndexPage[0]?.heroSecondaryButton?.page?.url
          ),
        }
      : null,
    verticalText: data?.serviceIndexPage[0].heroVerticalText?.name || "",
  };
}
