import React from "react";
import { Container, Image, RichText } from "@italymondo/core-ui";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import Slider from "react-slick";
import {
  DesktopContainer,
  Heading,
  ImageWrapperDesktop,
  ImageWrapperMobile,
  MobileContainer,
  PhotoTileContainer,
} from "./styles";

export type PpPhotoTile = {
  description?: string;
  images: Array<{
    alt: string;
    src: ImageDataLike;
  }>;
  inverted?: boolean;
  sectionName?: string;
  title?: string;
};

export function PhotoTile({
  description,
  images,
  inverted = false,
  sectionName,
  title,
  ...rest
}: PpPhotoTile): React.ReactElement | null {
  const theme = useTheme();
  const mediumWidth = useMediaQuery(theme.breakpoints.up("sm"));
  const settings = {
    arrows: false,
    centerMode: true,
    centerPadding: mediumWidth ? "80px" : "40px",
    dots: false,
    infinite: false,
    slidesToScroll: 1,
    slidesToShow: 1,
    swipe: true,
  };

  if (images.length === 0) return null;

  return (
    <React.Fragment {...rest}>
      <DesktopContainer>
        <Heading>
          {sectionName ? (
            <Typography
              gutterBottom
              align="center"
              color="primary.light"
              component="p"
              variant="section--name"
            >
              {sectionName}
            </Typography>
          ) : null}
          {title ? (
            <RichText
              gutterBottom
              align="center"
              color="primary"
              text={title}
              variant="h2"
            />
          ) : null}
          {description ? (
            <RichText
              align="center"
              color="primary"
              text={description}
              variant="body1"
            />
          ) : null}
        </Heading>
        <PhotoTileContainer
          $isBigger={images.length > 4}
          $isInverted={inverted}
        >
          {images.map((image) => (
            <ImageWrapperDesktop
              key={image.alt}
              $isBigger={images.length > 4}
              $isInverted={inverted}
            >
              <GatsbyImage
                alt={image.alt}
                image={getImage(image.src)}
                style={{ height: "100%" }}
              />
            </ImageWrapperDesktop>
          ))}
        </PhotoTileContainer>
      </DesktopContainer>
      <MobileContainer>
        <Container>
          <Heading>
            {sectionName ? (
              <Typography
                gutterBottom
                align="center"
                color="primary.light"
                component="p"
                variant="section--name"
              >
                {sectionName}
              </Typography>
            ) : null}
            {title ? (
              <RichText
                gutterBottom
                align="center"
                color="primary"
                text={title}
                variant="h2"
              />
            ) : null}
            {description ? (
              <RichText
                align="center"
                color="primary"
                text={description}
                variant="body1"
              />
            ) : null}
          </Heading>
        </Container>
        <div>
          <Slider {...settings}>
            {images.map((image) => (
              <ImageWrapperMobile key={image.alt}>
                <Image alt={image.alt} src={image.src} />
              </ImageWrapperMobile>
            ))}
          </Slider>
        </div>
      </MobileContainer>
    </React.Fragment>
  );
}
