import React from "react";
import { getPathname } from "@italymondo/core-ui";
import { graphql } from "gatsby";
import { Layout, SEO } from "../../components";
import {
  About,
  ContactForm,
  Differences,
  Hero,
  Introduction,
  Metrics,
  OurProcess,
  PhotoTile,
  Services,
  TestimonialWall,
} from "../../sections/ServiceIndex";
import { ServiceIndexQuery } from "./index.generated";

type PpServicesIndexPage = {
  data: ServiceIndexQuery;
  location?: {
    pathname?: string;
  };
};

export default function ServicesIndexPage({
  data: {
    cms: { site },
  },
  location,
}: PpServicesIndexPage): React.ReactElement {
  const alertBarData = site.serviceIndexPage[0].alertBar?.message
    ? {
        link: {
          text: site.serviceIndexPage[0].alertBar?.anchorText || "",
          url: getPathname(site.serviceIndexPage[0].alertBar?.page.url) || "",
        },
        text: site.serviceIndexPage[0].alertBar?.message || "",
      }
    : null;

  return (
    <Layout
      alertBar={alertBarData}
      showProgressBar={site.serviceIndexPage[0].progressBar}
    >
      <SEO
        description={site.serviceIndexPage[0]?.listingSummary || ""}
        image={site.serviceIndexPage[0]?.listingImage?.url}
        location={location}
        title={site.serviceIndexPage[0]?.listingTitle || ""}
      />
      <Hero />
      <Introduction />
      <Services />
      <About />
      <Metrics />
      <Differences />
      <PhotoTile />
      <OurProcess />
      {/* <TestimonialWall /> */}
      <ContactForm />
    </Layout>
  );
}

export const query = graphql`
  query ServiceIndex {
    cms {
      site(hostname: "www.italymondo.com") {
        serviceIndexPage: pages(
          contentType: "italymondo_services.IMServiceIndexPage"
        ) {
          ... on CMS_IMServiceIndexPage {
            alertBar {
              anchorText
              message
              page {
                url
              }
            }
            heroImage {
              url
            }
            listingImage {
              url
            }
            listingSummary
            listingTitle
            progressBar
          }
        }
      }
    }
  }
`;
