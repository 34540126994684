import React from "react";
import { Differences as DifferencesComponent } from "@italymondo/core-ui";
import { useStaticDifferencesQuery } from "./query";

export function Differences(): React.ReactElement | null {
  const data = useStaticDifferencesQuery();

  if (!data?.serviceIndexPage[0]) {
    return null;
  }

  return (
    <DifferencesComponent
      darkBackground
      description={data?.serviceIndexPage[0].differenceDescription || ""}
      differences={[
        {
          data: data?.serviceIndexPage[0].differences.map((d) => d.difference),
        },
      ]}
      sectionName={data?.serviceIndexPage[0].differenceSectionName || ""}
      title={data?.serviceIndexPage[0].differenceTitle || ""}
    />
  );
}
