import { graphql, useStaticQuery } from "gatsby";
import { ServiceIndexContactFormQuery } from "./query.generated";

export type CMSContactFormData = ServiceIndexContactFormQuery["cms"];

export function useStaticContactFormQuery(): CMSContactFormData {
  const data = useStaticQuery<ServiceIndexContactFormQuery>(
    graphql`
      query ServiceIndexContactForm {
        cms {
          site(hostname: "www.italymondo.com") {
            serviceIndexPage: pages(
              contentType: "italymondo_services.IMServiceIndexPage"
            ) {
              ... on CMS_IMServiceIndexPage {
                contactFormSectionName
                footerContactForm {
                  confirmationPage {
                    url
                  }
                  description
                  title
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms;
}
