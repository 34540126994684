import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import { shouldForwardProp } from "../utils";

type PpFullWidth = {
  $fullWidth: boolean;
};

export const StyledImg = styled(GatsbyImage, {
  shouldForwardProp,
})<PpFullWidth>`
  ${({ $fullWidth, theme }): SerializedStyles => css`
    border-radius: ${theme.shape.siteBorderRadius.image} !important;
    box-shadow: ${theme.shadows[1]};
    overflow: hidden !important;
    .styled-image,
    img,
    div[data-placeholder-image] {
      border-radius: ${theme.shape.siteBorderRadius.image} !important;
      ${$fullWidth && "width: 100%"};
    }
  `};
`;
