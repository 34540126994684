import React from "react";
import { Process } from "@italymondo/core-ui";
import { useStaticOurProcessQuery } from "./query";

export function OurProcess(): React.ReactElement | null {
  const data = useStaticOurProcessQuery();

  if (!data) {
    return null;
  }

  return (
    <Process
      ctaLink={data.serviceIndexPage[0]?.process?.cta}
      description={data.serviceIndexPage[0].process.description || ""}
      media={data.serviceIndexPage[0].processMedia[0]}
      mediaPlacement={data.serviceIndexPage[0].processMediaPlacement}
      sectionName={data.serviceIndexPage[0].processSectionName || ""}
      steps={data.serviceIndexPage[0].process.steps.map((step) => ({
        description: step.description,
        shadowHeader: step.step,
        title: step.name,
      }))}
      title={data.serviceIndexPage[0]?.process.title || ""}
    />
  );
}
